import { ImmutableArray } from "@hookstate/core"
import React, { useState } from "react"
import styled from "styled-components"
import branding from "../../branding/branding"
import TextField from "../Field/Text"

export const RightArrow = styled.div`
    width: 8px;
    height: 8px;
    border-top: 2px solid #727272;
    border-right: 2px solid #727272;
    transform: rotate(45deg);
`

export const DownArrow = styled.div`
    width: 8px;
    height: 8px;
    border-top: 2px solid #727272;
    border-right: 2px solid #727272;
    transform: rotate(135deg);
`

export const Checkbox = styled.div`
    cursor: pointer;
    height: 15px;
    width: 15px;
    border: 1px solid #727272;
    position: relative;

    &.checked {
        background: #d0d0d0;
    }

    &.checked:after {
        content: "";
        position: absolute;
        top: 2px;
        left: 1px;
        width: 11px;
        height: 6.5px;
        border-radius: 2px;
        border-width: 1.5px;
        border: 3px solid #ffff;
        border-top: none;
        border-right: none;
        background: transparent;
        transform: rotate(-45deg);
    }

    &.selected {
        background: #727272;
    }

    &.selected:after {
        content: "";
        position: absolute;
        top: 2px;
        left: 1px;
        width: 11px;
        height: 6.5px;
        border-radius: 2px;
        border-width: 1.5px;
        border: 3px solid #ffff;
        border-top: none;
        border-right: none;
        background: transparent;
        transform: rotate(-45deg);
    }

    &.blocked {
        cursor: not-allowed;
        user-select: none;
    }
`
export const NodeText = styled.div`
    padding: 0 20px;
    cursor: pointer;
    &.highlighted {
        color: ${branding.configuration.maincolor || "#6DD400"};
    }
`

export const TreeWrapper = styled.div`
    & > *:first-child {
        padding-top: 0;
    }
`

interface TreeItemWrapperProps {
    unitsLeft: number
}

export const TreeItemWrapper = styled.div<TreeItemWrapperProps>`
    display: flex;
    align-items: center;
    padding: 25px;
    border-bottom: 1px solid #d9d9d9;
    margin-left: ${(props) => `${props.unitsLeft * 25}px`};

    &.targetGroupWrapper {
        padding-left: 0px;
        margin-left: 0;
    }
`

export interface TreeItemData {
    id: string
    rootId: string
    text: string
    code: string
    checked: boolean
    selected: boolean
    relevant: boolean
    parent: TreeItemData | null
    nodes: TreeItemData[]
    credits?: string
}

export interface TreeData {
    nodes: TreeItemData[]
}

export interface TreeItemProps {
    showCode: boolean
    node: TreeItemData
    routeNodesExpanded?: boolean
    depth: number
    selection: Map<string, boolean>
    onSelectionChanged: (id: string, rootId: string, credits?: string) => void
    includeCredits?: boolean
    creditsValue?: string
    selectionBlockAllowed?: boolean
    selectionBlocked?: boolean
}

function formatText(node: TreeItemData, showCode: boolean): string {
    return showCode && node.code ? `${node.code} ${node.text}` : node.text
}

export const TreeCheckItem: React.FC<TreeItemProps> = ({
    showCode,
    node,
    routeNodesExpanded = false,
    depth,
    selection,
    onSelectionChanged,
    includeCredits,
    selectionBlockAllowed,
    selectionBlocked
}) => {
    const [expanded, setExpanded] = useState(routeNodesExpanded && depth === 0)
    const [credits, setCredits] = useState<string>(node.credits ?? "0")
    const checkboxClassName = `${selectionBlockAllowed && selectionBlocked ? "blocked" : ""} ${
        node.selected ? "selected" : node.checked ? "checked" : ""
    }`

    return (
        <>
            <TreeItemWrapper unitsLeft={depth}>
                <Checkbox
                    id={node.id}
                    className={checkboxClassName}
                    onClick={() => {
                        if (selectionBlockAllowed && selectionBlocked) return

                        onSelectionChanged(node.id, node.rootId, credits)
                    }}
                />
                <NodeText className={node.relevant ? "highlighted" : ""} onClick={() => setExpanded(!expanded)}>
                    {formatText(node, showCode)}
                </NodeText>
                {includeCredits && (
                    <div style={{ marginLeft: "auto" }}>
                        <TextField
                            name="credits"
                            label="Credits"
                            placeholder=""
                            value={credits}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => setCredits(e.target.value)}
                        />
                    </div>
                )}

                {expanded && node.nodes && node.nodes.length > 0 && <DownArrow />}
                {!expanded && node.nodes && node.nodes.length > 0 && <RightArrow />}
            </TreeItemWrapper>
            {expanded &&
                node.nodes?.map((item) => (
                    <TreeCheckItem
                        key={item.id}
                        showCode={showCode}
                        node={item}
                        depth={depth + 1}
                        selection={selection}
                        onSelectionChanged={onSelectionChanged}
                        selectionBlockAllowed={selectionBlockAllowed}
                        selectionBlocked={!branding.rootCategoriesSelectable && item.nodes.length > 0}
                    />
                ))}
        </>
    )
}

interface TreeProps {
    showCode: boolean
    rootNode: TreeData
    selection: Map<string, boolean>
    expandedRootNodes?: ImmutableArray<string>
    onSelectionChanged: (id: string, rootId: string, credits?: string) => void
    includeCredits?: boolean
    selectionBlockAllowed?: boolean
}

const TreeCheck: React.FC<TreeProps> = ({
    showCode,
    rootNode,
    expandedRootNodes,
    selection,
    onSelectionChanged,
    includeCredits,
    selectionBlockAllowed
}) => {
    return (
        <TreeWrapper>
            {rootNode.nodes.map((item) => (
                <TreeCheckItem
                    key={item.id}
                    showCode={showCode}
                    node={item}
                    routeNodesExpanded={expandedRootNodes && expandedRootNodes.length > 0 && expandedRootNodes.includes(item.id)}
                    depth={includeCredits ? 1 : 0}
                    selection={selection}
                    onSelectionChanged={onSelectionChanged}
                    includeCredits={includeCredits}
                    selectionBlockAllowed={selectionBlockAllowed}
                    selectionBlocked={!branding.rootCategoriesSelectable && item.nodes.length > 0}
                />
            ))}
        </TreeWrapper>
    )
}

export default TreeCheck
