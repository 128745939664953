import { BrandingImpl } from "../BrandingConfiguration"
import { CategoriesCountableConfiguration } from "./configurations/global/utilityConfigs/CategoriesCountableConfiguration"
import { ErrorMessagesConfiguration } from "./configurations/global/validation/ErrorMessagesConfiguration"
import { LanguageSwitchConfiguration } from "./configurations/global/pageElements/LanguageSwitchConfiguration"
import { ModalPublishConfiguration } from "./configurations/global/pageElements/ModalPublishConfiguration"
import { ValidationMessagesConfiguration } from "./configurations/global/validation/ValidationMessagesConfiguration"
import { ActionbarConfiguration } from "./configurations/global/pageElements/ActionBarConfiguration"
import { SidebarConfiguration } from "./configurations/global/pageElements/SideBarConfiguration"
import { TopBarConfiguration } from "./configurations/global/pageElements/TopBarConfiguration"
import { QrCodesConfiguration } from "./configurations/global/utilityConfigs/QrCodesConfiguration"
import { EventCloudProductsConfiguration } from "./configurations/global/utilityConfigs/EventCloudProductsConfiguration"
import { ProfilePageConfiguration } from "./configurations/content/profile/ProfilePageConfiguration"
import { LinksPageConfiguration } from "./configurations/content/LinksPageConfiguration"
import { CategoriesPageConfiguration } from "./configurations/content/CategoriesPageConfiguration"
import { LicencesPageConfiguration } from "./configurations/content/LicencesPageConfiguration"
import { ProductsPageConfiguration } from "./configurations/content/products/ProductsPageConfiguration"
import { ProductsForCollectionsPageConfiguration } from "./configurations/content/products/ProductsForCollectionsPageConfiguration"
import { TrademarksPageConfiguration } from "./configurations/content/TrademarksPageConfiguration"
import { TeamPageConfiguration } from "./configurations/content/team/TeamPageConfiguration"
import { MultimediaPageConfiguration } from "./configurations/content/multimedia/MultimediaPageConfiguration"
import { ExhibitorNewsPageConfiguration } from "./configurations/content/news/ExhibitorNewsPageConfiguration"
import { OrganizerNewsPageConfiguration } from "./configurations/content/news/OrganizerNewsPageConfiguration"
import { StoryNewsPageConfiguration } from "./configurations/content/news/StoryNewsPageConfiguration"
import { EventDatesPageConfiguration } from "./configurations/content/eventDates/EventDatesPageConfiguration"
import { NonCuratedEventDatesPageConfiguration } from "./configurations/content/eventDates/NonCuratedEventDatesPageConfiguration"
import { ShowroomsPageConfiguration } from "./configurations/content/ShowroomsPageConfiguration"
import { CouponsPageConfiguration } from "./configurations/content/CouponsPageConfiguration"
import { JobsPageConfiguration } from "./configurations/content/JobsPageConfiguration"
import { NotificationTemplatesPageConfiguration } from "./configurations/content/NotificationTemplatesPageConfiguration"
import { PrintPageConfiguration } from "./configurations/content/print/PrintPageConfiguration"

export interface Branding {
    /**
     * @title App & General Configuration
     * @description Configuration information for the app to be runnable
     * @title_de App & Allgemeine Konfiguration
     * @description_de Konfigurationsinformationen, damit die App überhaupt läuft
     */
    configuration: Configuration
    /**
     * @title Page Title
     * @description Title displayed in browser window for the app
     * @title_de Seitentitel
     * @description_de Titel im Browser-Fenter für die App
     */
    pageTitle: string
    /**
     * @title Application text languages
     * @description Languages in which the application's titles, labels, descriptions and other texts can be displayed
     * @title_de App-Textsprachen
     * @description_de Sprachen, in denen Titel, Beschriftungen, Beschreibungen und andere Texte der App angezeigt werden können
     */
    availableLanguages: string[]
    /**
     * @title Content languages
     * @description Languages in which the information about the company and its products, trademarks, job offers etc. can be entered
     * @title_de Inhaltssprachen
     * @description_de Sprachen, in denen Informationen über das Unternehmen und seine Produkte, Marken, Stellenangebote usw. eingegeben werden können
     */
    contentLanguages: string[]
    /**
     * @title Show limit counter
     * @description Toggle visibility of counter for the added entities
     * @title_de Limitzähler anzeigen
     * @description_de Schalten Sie die Sichtbarkeit des Zählers für die hinzugefügten Entitäten um
     */
    showCounter: boolean
    /**
     * @title Limit counter label
     * @description Template for the limit counter label
     * @title_de Begrenzungszähleretikett
     * @description_de Vorlage für die Begrenzungszähleretikett
     */
    lblLimit: LocalizedString
    /**
     * @title Root categories selectable
     * @description Enable/disable selection of categeries that have child elements
     * @title_de Stammkategorien wählbar
     * @description_de Aktivieren/deaktivieren Sie die Auswahl von Kategorien, die untergeordnete Elemente haben
     */
    rootCategoriesSelectable: boolean
    /**
     * @title Use config data V2
     * @description Toggle usage of new version of shop assets endpoint
     * @title_de Konfigurationsdaten V2 verwenden
     * @description_de Schalten Sie die Verwendung der neuen Version des Shop-Assets-Endpoints um
     */
    useConfigDataV2: boolean
    /**
     * @title Use uppseling for unavailable sections
     * @description Toggle the usage of upselling functionalities for pages/sections without available service keys
     * @title_de Verwenden Sie Upselling für nicht verfügbare Abschnitte
     * @description_de Umschalten der Nutzung von Upselling-Funktionalitäten für Seiten/Abschnitte ohne verfügbare Serviceschlüssel
     */
    useUpselling: boolean
    /**
     * @title Loader bg color
     * @description Background color for the centered loader
     * @title_de Loader bg color
     * @description_de Background color for the centered loader
     */
    loaderBgColor: string
    /**
     * @title Loader spin color
     * @description Top border color for the centered loader
     * @title_de Loader spin color
     * @description_de Top border color for the centered loader
     */
    loaderSpinColor: ColorString
}

export interface Configuration {
    /**
     * @title Topic name
     * @description Name of the topic for the data access (e.g. 2024_iaa_transportation)
     * @title_de Topic-Name
     * @description_de Name des topics für den Datenzugriff (z.b. 2024_iaa_transportation)
     */
    topicName: string
    /**
     * @title Series of topics token
     * @description Token for access to the series of topics data
     * @title_de Series-Of-Topics-Token
     * @description_de Token für den Zugriff auf die Series-Of-Topics-Daten
     */
    sotAccessToken: string
    /**
     * @title Open Graph title
     * @description Title for the meta-tag og:title
     * @title_de Open Graph Titel
     * @description_de Titel für das og:title meta-tag
     */
    ogTitle: LocalizedString
    /**
     * @title Open Graph description
     * @description Title for the meta-tag og:title
     * @title_de Open Graph Beschreibung
     * @description_de Titel für das og:description meta-tag
     */
    ogDescription: LocalizedString
    /**
     * @title Main color
     * @description Dominant color in the application
     * @title_de Hauptfarbe
     * @description_de Dominante Farbe in der Applikation
     */
    maincolor: ColorString
    /**
     * @title Days of fair/conference (format: YYYY-MM-dd)
     * @description List of dates on which the fair/conference is happening
     * @title_de Messe-/Konferenztage (Format: JJJJ-MM-tt)
     * @description_de Liste der Termine, an denen die Messe/Konferenz stattfindet
     */
    eventDays: string[]
    /**
     * @title Event Time Zone
     * @description Time zone of the the whole event. Usually this will be the timezone that corresponds to the geographic location of the event. Example "Europe/Berlin". Valid time zone names can be found in the column TZ indentifier here: https://en.wikipedia.org/wiki/List_of_tz_database_time_zones.
     * @default Europe/Berlin
     * @title_de Event-Zeitzone
     * @description_de Zeitzone der Veranstaltung. In der Regel entspricht dies der Zeitzone des geografischen Ortes des Events. Beispiel: "Europe/Berlin". Valide Zeitzonen können der Spalte TZ identifier hier entnommen werden: https://en.wikipedia.org/wiki/List_of_tz_database_time_zones.
     */
    eventTimeZone: string
    /**
     * @title Date format
     * @description Calendar picker's date format
     * @title_de Datumsformat
     * @description_de Datumsformat der Kalenderauswahl
     */
    dateFormat: string
    /**
     * @title Time format
     * @description Calendar picker's time format
     * @title_de Time format
     * @description_de Zeitformat der Kalenderauswahl
     */
    timeFormat: string
    /**
     * @title Categories code visibility
     * @description Toggle visibility of category code in the Categories page and entities' categories sections
     * @title_de Kategorien Code Sichtbarkeit
     * @description_de Sichtbarkeit des Kategoriecodes auf der Seite „Kategorien“ und in den Kategorienabschnitten der Entitäten umschalten
     */
    showCategoriesCode: boolean
    /**
     * @title Licences code visibility
     * @description Flag to enable the licences' code visibility
     * @title_de Lizenzen Code Sichtbarkeit
     * @description_de Sichtbarkeit des Kategoriecodes auf der Seite „Lizenzen“ umschalten
     */
    showLicencesCode: boolean
    /**
     * @title Search bar hint
     * @description Hint for the search bar input field
     * @title_de Suchleistenhinweis
     * @description_de Hinweis zum Eingabefeld der Suchleiste
     */
    hntSearch: LocalizedString
    /**
     * @title Chips hint
     * @description Hint for the chips input field
     * @title_de Chips Hinweis
     * @description_de Hinweis zum Chips-Eingabefeld
     */
    hntChips: LocalizedString
    /**
     * @title Dropdown filter placeholder
     * @description Placeholder for dropdown filters, displayed when there is no selected value
     * @title_de Platzhalter für Dropdown-Filter
     * @description_de Platzhalter für Dropdown-Filter, wird angezeigt, wenn kein Wert ausgewählt ist
     */
    lblEmptySelect: LocalizedString
    /**
     * @title Publishing modal configuration
     * @description Configuration for the elements of the publishing modal
     * @title_de Konfiguration des Veröffentlichungsmodals
     * @description_de Konfiguration für die Elemente des Veröffentlichungsmodals
     */
    modalPublish: ModalPublishConfiguration
    /**
     * @title Language switch configuration
     * @description Labels for the language switch
     * @title_de Konfiguration des Sprachschalters
     * @description_de Beschriftungen für den Sprachschalter
     */
    languageSwitch: LanguageSwitchConfiguration
    /**
     * @title Action bar configuration
     * @description Configuration for the action bar elements
     * @title_de Action-Bar-Konfiguration
     * @description_de Konfiguration für die Action-Bar-Elemente
     */
    actionbar: ActionbarConfiguration
    /**
     * @title Sidebar configuration
     * @description Configuration for the sidebar elements
     * @title_de Sidebar-Konfiguration
     * @description_de Konfiguration für die Sidebar-Elemente
     */
    sidebar: SidebarConfiguration
    /**
     * @title Event Cloud products configuration
     * @description Configuration for the Event Cloud products list
     * @title_de Konfiguration der Event-Cloud-Produkte
     * @description_de Konfiguration für die Liste der Event-Cloud-Produkte
     */
    eventCloudProducts: EventCloudProductsConfiguration
    /**
     * @title QR codes configuration
     * @description Configuration for the QR code download functionality
     * @title_de Konfiguration der QR-Codes
     * @description_de Konfiguration für die QR-Code-Download-Funktionalität
     */
    qrCodes: QrCodesConfiguration
    /**
     * @title Top bar configuration
     * @description Configuration for the top bar elements
     * @title_de Top-Bar-Konfiguration
     * @description_de Konfiguration für die Top-Bar-Elemente
     */
    topBar: TopBarConfiguration
    /**
     * @title Profile page configuration
     * @description Labels for the Profile page
     * @title_de Konfiguration der Profilseite
     * @description_de Beschriftungen für die Profilseite
     */
    profilePage: ProfilePageConfiguration
    /**
     * @title Links page configuration
     * @description Labels for the Links page
     * @title_de Konfiiguration der Links-Seite
     * @description_de Beschriftungen für die Seite "Links"
     */
    linksPage: LinksPageConfiguration
    /**
     * @title Categories page configuration
     * @description Labels for the Categories page
     * @title_de Konfiguration der Kategorieseite
     * @description_de Beschriftungen für die Seite "Kategorien"
     */
    categoriesPage: CategoriesPageConfiguration
    /**
     * @title Licences page configuration
     * @description Labels for the Licences page
     * @title_de Konfiguration der Lizenzen-Seite
     * @description_de Beschriftungen für die Seite "Lizenzen"
     */
    licencesPage: LicencesPageConfiguration
    /**
     * @title Products page configuration
     * @description Labels for the Products page
     * @title_de Konfiguration der Produktseite
     * @description_de Etiketten für die Seite "Produkte"
     */
    productsPage: ProductsPageConfiguration
    /**
     * @title Products for collections page configuration
     * @description Labels for the Products page
     * @title_de Konfiguration der Seite "Produkte für Sammlungen"
     * @description_de Etiketten für die Seite "Produkte für Sammlungen"
     */
    productsForCollectionsPage: ProductsForCollectionsPageConfiguration
    /**
     * @title Trademarks page configuration
     * @description Labels for the Trademarks page
     * @title_de Konfiguration der Markenseite
     * @description_de Etiketten für die Seite "Marken"
     */
    trademarksPage: TrademarksPageConfiguration
    /**
     * @title Team page configuration
     * @description Labels for the Team page
     * @title_de Konfiguration der Teamseite
     * @description_de Beschriftungen für die Seite "Team"
     */
    teamPage: TeamPageConfiguration
    /**
     * @title Multimedia page configuration
     * @description Labels for the Multimedia page
     * @title_de Konfiguration der Multimedia-Seite
     * @description_de Beschriftungen für die Multimedia-Seite
     */
    multimediaPage: MultimediaPageConfiguration
    /**
     * @title Exhibitor News page configuration
     * @description Labels for the Exhibitor News page
     * @title_de Konfiguration der Exhibitor-News-Seite
     * @description_de Labels für die Exhibitor-News-Seite
     */
    newsPage: ExhibitorNewsPageConfiguration
    /**
     * @title Organizer News page configuration
     * @description Labels for the Organizer News page
     * @title_de Konfiguration der Organizer-News-Seite
     * @description_de Labels für die Organizer-News-Seite
     */
    organizerNewsPage: OrganizerNewsPageConfiguration
    /**
     * @title Stories page configuration
     * @description Labels for the Stories page
     * @title_de Konfiguration der Stories-Seite
     * @description_de Labels für die Stories-Seite
     */
    storyNewsPage: StoryNewsPageConfiguration
    /**
     * @title Event dates page configuration
     * @description Labels for the Event dates page
     * @title_de Konfiguration der Veranstaltungsterminseite
     * @description_de Konfiguration für die Elemente der Veranstaltungsterminseite
     */
    eventDatesPage: EventDatesPageConfiguration
    /**
     * @title Exhibitor Events page configuration
     * @description Labels for the Exhibitor Events page
     * @title_de Konfiguration der Seite Aussteller-Events
     * @description_de Konfiguration für die Elemente der Seite Aussteller-Events
     */
    nonCuratedEventDatesPage: NonCuratedEventDatesPageConfiguration
    /**
     * @title Showrooms page configuration
     * @description Labels for the Showrooms page
     * @title_de Konfiguration der Showrooms-Seite
     * @description_de Konfiguration für die Elemente der Showrooms-Seite
     */
    showroomsPage: ShowroomsPageConfiguration
    /**
     * @title Coupons page configuration
     * @description Labels for the Coupons page
     * @title_de Konfiguration der Couponseite
     * @description_de Konfiguration für die Elemente der Couponseite
     */
    couponsPage: CouponsPageConfiguration
    /**
     * @title Job offers page configuration
     * @description Labels for the job offers page
     * @title_de Konfiguration der Stellenangeboten-Seite
     * @description_de Konfiguration für die Elemente der Stellenangeboten-Seite
     */
    jobsPage: JobsPageConfiguration
    /**
     * @title Notification templates page configuration
     * @description Labels for the Notification templates page
     * @title_de Seitenkonfiguration „Notification-Templates“
     * @description_de Beschriftungen für die Seite „Notification-Templates“
     */
    notificationTemplatesPage: NotificationTemplatesPageConfiguration
    /**
     * @title Print catalogue page configuration
     * @description Labels for the Print catalogue page
     * @title_de Konfiguration der Katalogseite
     * @description_de Konfiguration für die Elemente der Katalogseite
     */
    printPage: PrintPageConfiguration
    /**
     * @title Validation messages
     * @description Configuration for the validation messages
     * @title_de Validierungsmeldungen
     * @description_de Konfiguration für die Validierungsmeldungen
     */
    validationMessages: ValidationMessagesConfiguration
    /**
     * @title Error messages
     * @description Configuration for the error messages
     * @title_de Fehlermeldungen
     * @description_de Konfiguration für die Fehlermeldungen
     */
    errorMessages: ErrorMessagesConfiguration
    /**
     * @title Countable categories
     * @description Lists of categories that are counted against the service key limit
     * @title_de Zählbare Kategorien
     * @description_de Listen der Kategorien, die auf das Service-Key-Limit angerechnet werden
     */
    countableCategories: CategoriesCountableConfiguration
}

export type LocalizedString = string & { __localizedBrand?: undefined }
export type ColorString = string & { __colorBrand?: undefined }

declare let branding: Branding
branding = new BrandingImpl(branding, "en")
const brandingImplCallable = branding as any

function getBranding(): Branding {
    return branding
}
export default getBranding()

export function setBrandingLanguage(lang: string) {
    brandingImplCallable.setLang(lang)
}
