import { LocalizedString } from "../../../branding"

export type EventCloudProductKey = "EventGuide" | "Widget" | "Mobile"

export enum EventCloudProduct {
    EVENT_GUIDE = "EVENT_GUIDE",
    MOBILE_GUIDE = "MOBILE_GUIDE",
    WEB_WIDGETS = "WEB_WIDGETS"
}

export interface EventCloudProductsConfiguration {
    /**
     * @title Event Cloud products list
     * @description List of Event Cloud products that are in use for this topic
     * @title_de Liste der Event-Cloud-Produkte
     * @description_de Liste der Event-Cloud-Produkte, die für dieses Topic verwendet werden
     */
    list: EventCloudProduct[]
    /**
     * @title Name of Event Guide
     * @description Customized name of the product Event Guide
     * @title_de Name des Event-Guides
     * @description_de Customized name of the product Event Guide
     */
    nameEventGuide: string
    /**
     * @title Name of Mobile Guide
     * @description Customized name of the product Mobile Guide
     * @title_de Name des Mobile-Guides
     * @description_de Customized name of the product Mobile Guide
     */
    nameMobileGuide: string
    /**
     * @title Name of Web Widgets
     * @description Customized name of the product Web Widgets
     * @title_de Name des Web Widgets
     * @description_de Customized name of the product Web Widgets
     */
    nameWebWidgets: string
    /**
     * @title Switcher title
     * @description Title of the options switcher of the Event Cloud products
     * @title_de Switcher-Titel
     * @description_de Titel des Switchers der Event-Cloud-Produkte
     */
    titleSwitcher: LocalizedString
}
