import React from "react"
import styled from "styled-components"
import { State } from "@hookstate/core"
import { Validation } from "@hookstate/validation"
import RadioField from "../../components/Field/Radio"
import { hasErrors } from "../../globalStates/utils"
import branding from "../../branding/branding"
import { replacePlaceholder } from "../../globalStates/LanguageState"

const LanguageSwitchWrapper = styled.div`
    & > .form-check .form-check-label {
        white-space: nowrap;
    }
    & > .form-check > .invalid-feedback {
        margin-top: 0;
        margin-left: 0.25rem;
    }

    &.backgroundUploadVisible {
        margin-top: 50px !important;
        margin-bottom: 25px !important;
    }
`

interface LanguageSwitchProps {
    id?: string
    name: string
    value: string
    onChange: (language: string) => void
    entityState?: State<any, Validation>
    className?: string
}

const LanguageSwitch: React.FC<LanguageSwitchProps> = ({ id, name, value, onChange, entityState, className }) => {
    const contentLanguages = branding.contentLanguages

    const isInvalidEn = hasErrors("En", entityState)
    const isInvalidDe = hasErrors("De", entityState)

    function optionLabel(languageLabel: string, index: number) {
        const template =
            index === 0
                ? branding.configuration.languageSwitch.lblStandardTemplate
                : branding.configuration.languageSwitch.lblTranslationTemplate

        return replacePlaceholder(template, {
            language: String(languageLabel)
        })
    }

    if (contentLanguages.length < 2) {
        return null
    }

    return (
        <LanguageSwitchWrapper className={`form-check-inline mb-5 ${className || ""}`}>
            {contentLanguages.map((language: string, index: number) => {
                switch (language) {
                    case "en": {
                        return (
                            <RadioField
                                id={`${id}_En`}
                                key={language}
                                name={name}
                                value={value}
                                radioValue="En"
                                isInvalid={isInvalidEn}
                                label={optionLabel(branding.configuration.languageSwitch.lblEnOption, index)}
                                onChange={onChange}
                            />
                        )
                    }
                    case "de": {
                        return (
                            <RadioField
                                id={`${id}_De`}
                                key={language}
                                name={name}
                                value={value}
                                radioValue="De"
                                isInvalid={isInvalidDe}
                                label={optionLabel(branding.configuration.languageSwitch.lblDeOption, index)}
                                onChange={onChange}
                            />
                        )
                    }
                    default:
                        return null
                }
            })}
        </LanguageSwitchWrapper>
    )
}

export default LanguageSwitch
